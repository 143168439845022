<template>
  <div class="travelCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{
        typeGrade==1?"一级分类列表" :"二级分类列表"
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称:</span>
      <el-input v-model="list.typeName" placeholder="请输入分类名称"></el-input>
      <el-button type="primary" @click="getList()">查找</el-button>
      <el-button
        type="primary"
        @click="addDialogVisible = true"
        v-if="$store.state.powerList.indexOf('selectMall:typeList:add')!==-1"
        >添加</el-button
      >
      <el-button type="primary" v-if="list.parentId > 0" @click="$router.go(-1)"
        >返回上一级</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width:100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center" width="100">
      </el-table-column>
      <el-table-column
        align="center"
        prop="typeSort"
        label="排序"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.typeSort"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.typeSort }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="typeId"
        label="分类ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="typeName"
        label="分类名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="typeImg"
        label="分类图片"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            style="width:80px; height:80px"
            :src="row.typeImg"
            :preview-src-list="[row.typeImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="typeUpdateTime"
        label="最后修改时间"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            v-if="!list.parentId"
            @click="toNext(row)"
            >查看下级</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onEdit(row)"
            v-if="
              $store.state.powerList.indexOf('selectMall:typeList:edit')!==-1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onDel([row])"
            v-if="
              $store.state.powerList.indexOf('selectMall:typeList:delete')!==
              -1
            "
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <el-button
      style="margin-top:20px"
      type="primary"
      @click="onDel(selection)"
      v-if="$store.state.powerList.indexOf('selectMall:typeList:delete')!==-1"
      >批量刪除</el-button
    >

    <!-- 添加分类弹层 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加分类"
      :visible.sync="addDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类排序">
          <el-input-number
            :precision="0"
            :step="1"
            :min="0"
            v-model="addForm.typeSort"
            placeholder="请输入分类排序"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input
            v-model="addForm.typeName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="addDialogVisible"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
        </el-form-item>
        <el-form-item>
          <span style="color:red"
            >每张图片不能超过500K，建议尺寸：{{
              typeGrade==1?"40*40px" :"123*123px"
            }}</span
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑分类弹层 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑分类"
      :visible.sync="editDialogVisible"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类排序">
          <el-input-number
            :precision="0"
            :step="1"
            :min="0"
            v-model="editForm.typeSort"
            placeholder="请输入分类排序"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input v-model="editForm.typeName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="editDialogVisible"
            :images="editForm.typeImg"
            @addImageStr="(e) => addImageStr(e, 2)"
          ></send-image>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  typeList,
  typeEdit,
  typeDelete,
  expressEdit,
} from "../../api/selectMall";
import { sendFile } from "../../api/sendFile";
import sendImage from "../../components/sendImage.vue";
export default {
  components:{ sendImage },
  name:"selectMallTypeList",
  data() {
    return {
      addDialogVisible:false,
      editDialogVisible:false,
      addForm:{
        typeSort:0,
        typeName:"",
        typeImg:"",
      },
      editForm:{
        typeId:null,
        typeSort:"",
        typeName:"",
        typeImg:"",
      },
      list:{
        currentPage:1,
        pageSize:5,
        parentId:0,
        typeName:"",
      },
      selection:[],
      typeGrade:1, // 分类级别, 1 / 2
      tableData:[],
      pagination:{},
    };
  },
  watch:{
    // 利用watch方法检测路由变化：
    $route:function (to, from) {
      console.log(to.query);
      this.changeId();
    },
  },
  created() {
    this.changeId();
  },
  mounted() {},
  methods:{
    toNext(row) {
      this.tableData = [];
      this.list.currentPage = 1;
      this.$router.push("/selectMall/typeList?id=" + row.typeId);
    },
    changeId() {
      console.log("挂载, $route:", this.$route);
      this.list.parentId = Number(this.$route.query.id||0);
      // this.addForm.typeParentId = Number(this.$route.query.id||0);
      // this.editForm.typeParentId = Number(this.$route.query.id||0);
      console.log("changeId:", this.list);
      this.getList();
    },
    async getList() {
      let setData = new Object();
      setData = { ...this.list };
      if (setData.parentId > 0) {
        setData.typeGrade = 2;
        this.typeGrade = 2;
      } else {
        delete setData.parentId;
        setData.typeGrade = 1;
        this.typeGrade = 1;
      }
      const { data } = await typeList(setData);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async handleInputConfirm(row) {
      const { data } = await typeEdit({
        typeId:row.typeId,
        typeSort:Number(row.typeSort),
      });
      if (data.code!==0) {
        this.$message({
          message:"设置失败",
          type:"error",
        });
      } else {
        this.$message({
          message:"设置成功",
          type:"success",
        });
      }
      row.inputVisible = false;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async onAddOk() {
      if (this.addForm.typeName===""||this.addForm.typeSort==="") {
        this.$message({
          message:"请把数据填写完整",
          type:"warning",
        });
        return;
      }
      if (this.list.parentId > 0&&this.addForm.typeImg==="") {
        this.$message({
          message:"请把数据填写完整",
          type:"warning",
        });
        return;
      }
      let newData = this.addForm;
      newData.typeGrade = this.list.parentId > 0?2 :1;
      if (this.list.parentId > 0) newData.typeParentId = this.list.parentId;
      const { data } = await typeEdit(newData);
      if (data.code==0) {
        this.$message({
          message:"添加成功",
          type:"success",
        });
        this.addForm.typeName = "";
        this.addForm.typeImg = "";
      }
      this.addDialogVisible = false;
      this.getList();
    },
    //删除
    onDel(arr) {
      if (!arr||arr.length < 1) {
        this.$message({ message:"请选择删除项!", type:"error" });
        return;
      }
      let ids = arr
        .map((item) => {
          return item.typeId;
        })
        .join(",");

      this.$confirm("是否删除这条分类?", "提示", {
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning",
      })
        .then(async () => {
          const { data } = await typeDelete({
            ids:ids,
          });
          console.log(data);
          if (data.code==0) {
            this.$message({
              type:"success",
              message:data.msg,
            });
            this.getList();
          } else {
            this.$message({
              type:"error",
              message:data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type:"info",
            message:"已取消删除",
          });
        });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)&&!reg2.test(value)) {
        return this.$message({
          type:"warning",
          message:"请输入整数!",
        });
      }
    },
    async onEdit(row) {
      this.editForm.typeId = row.typeId;
      this.editForm.typeImg = row.typeImg;
      this.editForm.typeSort = row.typeSort;
      this.editForm.typeName = row.typeName;
      this.editDialogVisible = true;
    },
    async onEditOk() {
      const { data } = await typeEdit(this.editForm);
      console.log(data);
      if (data.code==0) {
        this.editDialogVisible = false;
        this.getList();
        this.$message({
          type:"success",
          message:"编辑成功",
        });
      } else {
        this.$message({
          type:"error",
          message:"编辑失败",
        });
      }
    },
    addImageStr(image, type) {
      if (type===1) {
        this.addForm.typeImg = image;
      } else if (type===2) {
        this.editForm.typeImg = image;
      }
    },
    handleSelectionChange(val) {
      this.selection = val;
      console.log("选择", val);
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.travelCateList {
  .el-breadcrumb {
    height:35px;
    border-bottom:1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top:50px;
    .el-input {
      width:200px;
      margin:0 40px 0 20px;
    }
  }
  .el-table {
    margin-top:30px;
    .el-table__body-wrapper {
      table {
        td {
          .cell {
            img {
              width:80px;
              height:80px;
            }
          }
        }
      }
    }
  }
  /deep/.el-dialog {
    .el-form-item__content {
      display:flex;
      .img {
        position:relative;
        height:100px;
        width:100px;
        margin:20px 20px;
        img {
          height:100px;
          width:100px;
          border:1px solid #c0ccda;
        }
        div {
          position:absolute;
          top:0;
          right:2px;
          height:10px;
          width:10px;
          z-index:100;
        }
      }
      .addImg {
        display:inline-block;
        width:100px;
        height:100px;
        border:1px dashed #c0ccda;
        text-align:center;
        line-height:100px;
        cursor:pointer;
        margin-top:20px;
        i {
          width:50px;
          height:50px;
          font-size:20px;
          color:#c0ccda;
        }
      }
    }
  }
  .el-pagination {
    margin-top:30px;
    float:right;
  }
}
</style>